<template>
  <div class="list">
    <div class="list-title">
      <div class="title-container">
        <Title :title="getTitle" />
        <ElIcon :size="16"
          ><CopyDocument
            :size="16"
            class="el-icon-copy-document copy-success"
            v-bind:data-clipboard-text="user"
            @click="copyAddress"
        /></ElIcon>
      </div>
      <div class="info">
        <span>{{ $t("txuser.totalof") }}</span>
        <span v-if="txsActive" class="num">{{
          $HelperTools.toFinancialVal(txs.TotalCount)
        }}</span>
        <span v-if="txsInternalActive" class="num">{{
          $HelperTools.toFinancialVal(txDetails.TotalCount)
        }}</span>
        <span v-if="nftTransactionsActive">
          <span class="num">
            {{ $HelperTools.toFinancialVal(nftTransactions.TotalCount) }}
          </span>
        </span>
        <span v-if="erc20TransactionsActive">
          <span class="num">
            {{ $HelperTools.toFinancialVal(erc20Transactions.TotalCount) }}
          </span>
        </span>
        <span>{{ $t("txuser.block") }}</span>
      </div>
    </div>
    <div class="s-title">{{ $t("txuser.overview") }}</div>
    <Summary :pltBalance="balance" :tokenHoldings="tokenHoldings" />
    <div class="tab-list">
      <div class="tab">
        <div class="item" @click="tabSwitch(0)">
          <div class="first">
            <div class="title">
              {{
                isSP ? $t("txuser.transactions_sp") : $t("txuser.transactions")
              }}
            </div>
            <div :class="txsActive ? 'line active' : 'line'"></div>
          </div>
        </div>
        <div class="item" @click="tabSwitch(1)">
          <div class="title">
            {{
              isSP
                ? $t("txuser.internaltransactions_sp")
                : $t("txuser.internaltransactions")
            }}
          </div>
          <div :class="txsInternalActive ? 'line active' : 'line'"></div>
        </div>
        <div class="item" @click="tabSwitch(2)">
          <div class="title">
            {{
              isSP
                ? $t("txuser.erc20transactions_sp")
                : $t("txuser.erc20transactions")
            }}
          </div>
          <div :class="erc20TransactionsActive ? 'line active' : 'line'"></div>
        </div>
        <div class="item" @click="tabSwitch(3)">
          <div class="title">
            {{
              isSP
                ? $t("txuser.nfttransactions_sp")
                : $t("txuser.nfttransactions")
            }}
          </div>
          <div :class="nftTransactionsActive ? 'line active' : 'line'"></div>
        </div>
      </div>
      <div v-if="txsActive">
        <List
          style="margin-top: 0; padding: 0"
          :data="txs.Transactions"
          :handleCurrentChange="handleCurrentChange"
          :handleCSVExport="exportCSVTxs"
          :rowDatas="rowDataTxs"
          :total="txs.TotalCount"
          :firstColumnWidth="230"
          :secondColumnWidth="115"
          :thirdColumnWidth="230"
          :fourthColumnWidth="230"
          :csvExport="true"
          :csvGenerating="generatingTransactionCSV"
          :csvPercentage="currentPercentageTransactions"
          :txHashColumn="0"
          :blockColumn="1"
          :fromColumn="2"
          :toColumn="3"
          :current-page="currentPage"
          :pageSize="this.pageSize"
        />
      </div>
      <div v-if="txsInternalActive">
        <List
          style="margin-top: 0; padding: 0"
          :data="txDetails.TransactionDetails"
          :handleCurrentChange="handleCurrentChange"
          :handleCSVExport="exportCSVTxDetails"
          :rowDatas="rowDatasTxDetails"
          :total="txDetails.TotalCount"
          :firstColumnWidth="180"
          :secondColumnWidth="170"
          :thirdColumnWidth="170"
          :fourthColumnWidth="170"
          :fifthColumnWidth="115"
          :sixthColumnWidth="215"
          :csvExport="true"
          :csvGenerating="generatingInternalTransactionsCSV"
          :csvPercentage="currentPercentageInternalTransactions"
          :txHashColumn="0"
          :fromColumn="1"
          :toColumn="2"
          :contractColumn="3"
          :current-page="currentPage"
          :pageSize="this.pageSize"
        />
      </div>
      <div v-if="nftTransactionsActive">
        <List
          style="margin-top: 0; padding: 0"
          :data="nftTransactions.TransactionDetails"
          :handleCurrentChange="handleCurrentChange"
          :handleCSVExport="exportCSVNftTransactions"
          :rowDatas="rowDatasNftTransactions"
          :total="nftTransactions.TotalCount"
          :firstColumnWidth="195"
          :secondColumnWidth="160"
          :thirdColumnWidth="160"
          :fourthColumnWidth="160"
          :fifthColumnWidth="100"
          :sixthColumnWidth="245"
          :csvExport="true"
          :csvGenerating="generatingNFTTransactionCSV"
          :csvPercentage="currentPercentageNFTTransactions"
          :txHashColumn="0"
          :fromColumn="1"
          :toColumn="2"
          :contractColumn="3"
          :current-page="currentPage"
          :pageSize="this.pageSize"
        />
      </div>
      <div v-if="erc20TransactionsActive">
        <List
          style="margin-top: 0; padding: 0"
          :data="erc20Transactions.TransactionDetails"
          :handleCurrentChange="handleCurrentChange"
          :handleCSVExport="exportCSVERC20Transactions"
          :rowDatas="rowDatasERC20Transactions"
          :total="erc20Transactions.TotalCount"
          :firstColumnWidth="195"
          :secondColumnWidth="160"
          :thirdColumnWidth="160"
          :fourthColumnWidth="160"
          :fifthColumnWidth="345"
          :csvExport="true"
          :csvGenerating="generatingERC20TransactionCSV"
          :csvPercentage="currentPercentageERC20Transactions"
          :txHashColumn="0"
          :fromColumn="1"
          :toColumn="2"
          :contractColumn="3"
          :current-page="currentPage"
          :pageSize="this.pageSize"
        />
      </div>
    </div>
    <div class="s-title mt20">
      {{ $t("txuser.nfts") }}
    </div>
    <List
      style="margin-top: 0"
      :data="nfts.UserNFTs"
      :handleCurrentChange="handleCurrentChangeSecondary"
      :handleCSVExport="exportCSVNfts"
      :rowDatas="rowDataNft"
      :total="nfts.TotalCount"
      :firstColumnWidth="200"
      :secondColumnWidth="260"
      :thirdColumnWidth="120"
      :fourthColumnWidth="120"
      :lastColumnWidth="320"
      :csvExport="true"
      :csvGenerating="generatingNftsCsv"
      :csvPercentage="currentPercentageNfts"
      :contractColumn="0"
      :tokenIdColumn="3"
      :current-page="currentPageSecondary"
      :pageSize="this.pageSize"
    />
  </div>
</template>

<script>
import {
  getTxsFromToUser,
  getPltHolderInfo,
  getAllNftsOfUser,
  getTxDetailsFromToUser,
  getAllNftTransactionsFromToUser,
  getERC20s,
  getERC20HolderInfo,
  getAllERC20TransactionsFromToUser,
} from "@/api/_common";
import Title from "@/components/SecondaryTitle.vue";
import List from "@/components/List.vue";
import Summary from "./summary";
import Helper from "@/utils/helper";
import { useMq } from "vue3-mq";
import Clipboard from "clipboard";
import { ElIcon } from "element-plus";
import { CopyDocument } from "@element-plus/icons";

export default {
  components: {
    Title,
    List,
    Summary,
    ElIcon,
    CopyDocument,
  },
  data() {
    return {
      txsActive: true,
      txsInternalActive: false,
      nftTransactionsActive: false,
      erc20TransactionsActive: false,
      txs: {},
      nfts: {},
      txDetails: {},
      nftTransactions: {},
      erc20Transactions: {},
      pageSize: this.$route.params.pageSize
        ? Number(this.$route.params.pageSize)
        : 10,
      balance: 0,
      user: this.$route.params.User,
      tokenHoldings: [],
      generatingTransactionCSV: false,
      generatingInternalTransactionsCSV: false,
      generatingNFTTransactionCSV: false,
      generatingERC20TransactionCSV: false,
      generatingNftsCsv: false,
      currentPercentageTransactions: 0,
      currentPercentageInternalTransactions: 0,
      currentPercentageNFTTransactions: 0,
      currentPercentageERC20Transactions: 0,
      currentPercentageNfts: 0,
      rowDataTxs: [
        {
          prop: "Hash",
          label: "txuser.hash",
          method: (row) =>
            `${row.Hash.substr(0, 8)}...${row.Hash.substr(58, 8)}`,
          status: (row) => row.Status,
        },
        {
          prop: "Height",
          label: "txuser.height",
          method: (row) => row.BlockNumber,
        },
        {
          prop: "From",
          label: "txuser.from",
          method: (row) =>
            `${row.From.substr(0, 8)}...${row.From.substr(34, 8)}`,
        },
        {
          prop: "To",
          label: "txuser.to",
          method: (row) => `${row.To.substr(0, 8)}...${row.To.substr(34, 8)}`,
        },
        {
          prop: "Time",
          label: "txuser.time",
          method: (row) => Helper.getTransDate(row.Time),
        },
      ],
      rowDataNft: [
        {
          prop: "Contract",
          label: "txuser.contract",
          method: (row) =>
            `${row.Contract.substr(0, 8)}...${row.Contract.substr(34, 8)}`,
        },
        {
          prop: "Name",
          label: "txuser.contractname",
          method: (row) => row.ContractInfo.Name,
        },
        {
          prop: "Symbol",
          label: "txuser.symbol",
          method: (row) => row.ContractInfo.Symbol,
        },
        {
          prop: "TokenID",
          label: "txuser.tokenid",
          method: (row) => row.Token,
        },
        {
          prop: "Token",
          label: "txuser.token",
          method: (row) => row.Profile.image,
        },
      ],
      rowDatasTxDetails: [
        {
          prop: "Hash",
          label: "txuser.parenthash",
          method: (row) =>
            `${row.TransactionHash.substr(0, 4)}...${row.TransactionHash.substr(
              62,
              4
            )}`,
          status: (row) => row.Status,
        },
        {
          prop: "From",
          label: "txuser.from",
          method: (row) =>
            `${row.From.substr(0, 4)}...${row.From.substr(38, 4)}`,
        },
        {
          prop: "To",
          label: "txuser.to",
          method: (row) => `${row.To.substr(0, 4)}...${row.To.substr(38, 4)}`,
        },
        {
          prop: "Contract",
          label: "txuser.contract",
          method: (row) =>
            `${row.Contract.substr(0, 4)}...${row.Contract.substr(38, 4)}`,
        },
        {
          prop: "Value",
          label: "txuser.value",
          method: (row) => row.Value,
        },
        {
          prop: "Time",
          label: "txuser.time",
          method: (row) => Helper.getTransDate(row.Time),
        },
      ],
      rowDatasNftTransactions: [
        {
          prop: "Hash",
          label: "txuser.hash",
          method: (row) =>
            `${row.TransactionHash.substr(0, 8)}...${row.TransactionHash.substr(
              58,
              8
            )}`,
          status: (row) => row.Status,
        },
        {
          prop: "From",
          label: "txuser.from",
          method: (row) =>
            `${row.From.substr(0, 6)}...${row.From.substr(36, 6)}`,
        },
        {
          prop: "To",
          label: "txuser.to",
          method: (row) => `${row.To.substr(0, 6)}...${row.To.substr(36, 6)}`,
        },
        {
          prop: "Contract",
          label: "txuser.contract",
          method: (row) =>
            `${row.Contract.substr(0, 6)}...${row.Contract.substr(36, 6)}`,
        },
        {
          prop: "TokenID",
          label: "txuser.tokenid",
          method: (row) => row.TokenInfo.Token,
        },
        {
          prop: "Name",
          label: "txuser.token",
          method: (row) => row.ContractInfo.Name,
        },
      ],
      rowDatasERC20Transactions: [
        {
          prop: "Hash",
          label: "txuser.hash",
          method: (row) =>
            `${row.TransactionHash.substr(0, 8)}...${row.TransactionHash.substr(
              58,
              8
            )}`,
          status: (row) => row.Status,
        },
        {
          prop: "From",
          label: "txuser.from",
          method: (row) =>
            `${row.From.substr(0, 6)}...${row.From.substr(36, 6)}`,
        },
        {
          prop: "To",
          label: "txuser.to",
          method: (row) => `${row.To.substr(0, 6)}...${row.To.substr(36, 6)}`,
        },
        {
          prop: "Contract",
          label: "txuser.contract",
          method: (row) =>
            `${row.Contract.substr(0, 6)}...${row.Contract.substr(36, 6)}`,
        },
        {
          prop: "Value",
          label: "txuser.value",
          method: (row) => row.Value,
        },
      ],
    };
  },
  async mounted() {
    if (!this.$route.query.page) {
      await this.$router.replace({
        query: {
          ...this.$route.query,
          page: 1,
        },
        params: {
          ...this.$route.params,
        },
      });
    }
    if (!this.$route.query.pageSecondary) {
      await this.$router.replace({
        query: {
          ...this.$route.query,
          pageSecondary: 1,
        },
        params: {
          ...this.$route.params,
        },
      });
    }
    if (this.$route.query && this.$route.query.tab) {
      if (Number(this.$route.query.tab) === 0) {
        this.txsActive = true;
        this.txsInternalActive = false;
        this.nftTransactionsActive = false;
        this.erc20TransactionsActive = false;
      }
      if (Number(this.$route.query.tab) === 1) {
        this.txsActive = false;
        this.txsInternalActive = true;
        this.nftTransactionsActive = false;
        this.erc20TransactionsActive = false;
      }
      if (Number(this.$route.query.tab) === 2) {
        this.txsActive = false;
        this.txsInternalActive = false;
        this.nftTransactionsActive = false;
        this.erc20TransactionsActive = true;
      }
      if (Number(this.$route.query.tab) === 3) {
        this.txsActive = false;
        this.txsInternalActive = false;
        this.nftTransactionsActive = true;
        this.erc20TransactionsActive = false;
      }
    }
    this.init();
  },
  methods: {
    init() {
      this.getTxs();
      this.getBalance();
      this.getNfts();
      this.getTxDetails();
      this.getNftTransactions();
      this.getTokenHoldings();
      this.getERC20Transactions();
    },
    async tabSwitch($newTab) {
      if ($newTab === 0) {
        this.txsActive = true;
        this.txsInternalActive = false;
        this.nftTransactionsActive = false;
        this.erc20TransactionsActive = false;
      }
      if ($newTab === 1) {
        this.txsActive = false;
        this.txsInternalActive = true;
        this.nftTransactionsActive = false;
        this.erc20TransactionsActive = false;
      }
      if ($newTab === 2) {
        this.txsActive = false;
        this.txsInternalActive = false;
        this.nftTransactionsActive = false;
        this.erc20TransactionsActive = true;
      }
      if ($newTab === 3) {
        this.txsActive = false;
        this.txsInternalActive = false;
        this.nftTransactionsActive = true;
        this.erc20TransactionsActive = false;
      }
      await this.handleCurrentChange(1, $newTab);
    },
    async getTxs() {
      const res = await getTxsFromToUser({
        User: this.user,
        PageNo: this.currentPage - 1,
        PageSize: this.pageSize,
      });
      this.txs = res;
    },
    async getBalance() {
      const res = await getPltHolderInfo({
        Address: this.user,
      });
      this.balance = res.Amount;
    },
    async getNfts() {
      const res = await getAllNftsOfUser({
        User: this.user,
        PageNo: this.currentPageSecondary - 1,
        PageSize: this.pageSize,
      });
      this.nfts = res;
    },
    async getTxDetails() {
      const res = await getTxDetailsFromToUser({
        User: this.user,
        PageNo: this.currentPage - 1,
        PageSize: this.pageSize,
      });
      this.txDetails = res;
    },
    async getNftTransactions() {
      const res = await getAllNftTransactionsFromToUser({
        User: this.user,
        PageNo: this.currentPage - 1,
        PageSize: this.pageSize,
      });
      this.nftTransactions = res;
    },
    async getTokenHoldings() {
      const res = await getERC20s({
        PageNo: 0,
        PageSize: 10,
      }).catch(() => console.error("Error fetching erc-20 contracts"));
      if (res) {
        for (let i = 0; i < res.Contracts.length; i++) {
          const res2 = await getERC20HolderInfo({
            Address: this.user,
            Contract: res.Contracts[i].Contract,
          }).catch(() =>
            console.error(
              `Error fetching erc-20 holder info for ${res.Contracts[i].Contract}`
            )
          );
          if (res2) {
            this.tokenHoldings.push({
              name: res.Contracts[i].Name,
              symbol: res.Contracts[i].Symbol,
              amount: Number(res2.Amount),
            });
          }
        }
      }
    },
    async getERC20Transactions() {
      const res = await getAllERC20TransactionsFromToUser({
        PageNo: this.currentPage - 1,
        PageSize: 10,
        User: this.user,
      }).catch(() => console.error("Error fetching erc-20 user transactions"));
      if (res) {
        this.erc20Transactions = res;
      }
    },
    async exportCSVTxs() {
      const titles = [
        "Txn Hash",
        "Status",
        "Height",
        "From",
        "To",
        "Created Time",
      ];
      const requestPageSize =
        this.txs.TotalCount >= 100000
          ? 10000
          : this.txs.TotalCount >= 10000
          ? 1000
          : 100;
      const numberOfPages = Math.ceil(this.txs.TotalCount / requestPageSize);
      if (numberOfPages > 3) {
        this.generatingTransactionCSV = true;
      }
      let csvDataPreprocessed = [];
      for (let i = 0; i < numberOfPages; i++) {
        const res = await getTxsFromToUser({
          User: this.user,
          PageNo: i,
          PageSize: requestPageSize,
        });
        csvDataPreprocessed = csvDataPreprocessed.concat(res.Transactions);
        this.currentPercentageTransactions = Math.round(
          ((i + 1) / numberOfPages) * 100
        );
      }
      const csvData = csvDataPreprocessed
        .map((tx) => {
          return {
            Hash: tx.Hash,
            Status: tx.Status == 1 ? "Confirmed" : "Failed",
            Height: tx.BlockNumber,
            From: tx.From,
            To: tx.To,
            Time: Helper.getTransDate(tx.Time),
          };
        })
        .map((tx) => [tx.Hash, tx.Status, tx.Height, tx.From, tx.To, tx.Time]);

      const rows = [titles, ...csvData];
      const csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.user}.csv`);
      document.body.appendChild(link);
      link.click();
      this.generatingTransactionCSV = false;
      this.currentPercentageTransactions = 0;
    },
    async exportCSVTxDetails() {
      const titles = [
        "Parent Txn Hash",
        "Status",
        "From",
        "To",
        "Contract",
        "Value",
        "Created Time",
      ];
      const requestPageSize =
        this.txDetails.TotalCount >= 100000
          ? 10000
          : this.txDetails.TotalCount >= 10000
          ? 1000
          : 100;
      const numberOfPages = Math.ceil(
        this.txDetails.TotalCount / requestPageSize
      );
      if (numberOfPages > 3) {
        this.generatingInternalTransactionsCSV = true;
      }
      let csvDataPreprocessed = [];
      for (let i = 0; i < numberOfPages; i++) {
        const res = await getTxDetailsFromToUser({
          User: this.user,
          PageNo: i,
          PageSize: requestPageSize,
        });
        csvDataPreprocessed = csvDataPreprocessed.concat(
          res.TransactionDetails
        );
        this.currentPercentageInternalTransactions = Math.round(
          ((i + 1) / numberOfPages) * 100
        );
      }
      const csvData = csvDataPreprocessed
        .map((tx) => {
          return {
            Hash: tx.TransactionHash,
            Status: tx.Status == 1 ? "Confirmed" : "Failed",
            From: tx.From,
            To: tx.To,
            Contract: tx.Contract,
            Value: tx.Value,
            Time: Helper.getTransDate(tx.Time),
          };
        })
        .map((tx) => [
          tx.Hash,
          tx.Status,
          tx.From,
          tx.To,
          tx.Contract,
          tx.Value,
          tx.Time,
        ]);

      const rows = [titles, ...csvData];
      const csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.user}_internal_txns.csv`);
      document.body.appendChild(link);
      link.click();
      this.generatingInternalTransactionsCSV = false;
      this.currentPercentageInternalTransactions = 0;
    },
    async exportCSVNftTransactions() {
      const titles = [
        "Hash",
        "Status",
        "From",
        "To",
        "Contract",
        "Token ID",
        "Name",
      ];
      const numberOfPages = Math.ceil(this.nftTransactions.TotalCount / 100);
      if (numberOfPages > 3) {
        this.generatingNFTTransactionCSV = true;
      }
      let csvDataPreprocessed = [];
      for (let i = 0; i < numberOfPages; i++) {
        const res = await getAllNftTransactionsFromToUser({
          User: this.user,
          PageNo: i,
          PageSize: 100,
        });
        csvDataPreprocessed = csvDataPreprocessed.concat(
          res.TransactionDetails
        );
        this.currentPercentageNFTTransactions = Math.round(
          (i / numberOfPages) * 100
        );
      }
      const csvData = csvDataPreprocessed
        .map((tx) => {
          return {
            Hash: tx.TransactionHash,
            Status: tx.Status == 1 ? "Confirmed" : "Failed",
            From: tx.From,
            To: tx.To,
            Contract: tx.Contract,
            TokenId: tx.TokenInfo.Token,
            Name: tx.ContractInfo.Name,
          };
        })
        .map((tx) => [
          tx.Hash,
          tx.Status,
          tx.From,
          tx.To,
          tx.Contract,
          tx.TokenId,
          tx.Name,
        ]);

      const rows = [titles, ...csvData];
      const csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.user}_nft_txns.csv`);
      document.body.appendChild(link);
      link.click();
      this.generatingNFTTransactionCSV = false;
      this.currentPercentageNFTTransactions = 0;
    },
    async exportCSVERC20Transactions() {
      const titles = ["Hash", "Status", "From", "To", "Contract", "Value"];
      const numberOfPages = Math.ceil(this.erc20Transactions.TotalCount / 100);
      if (numberOfPages > 3) {
        this.generatingERC20TransactionCSV = true;
      }
      let csvDataPreprocessed = [];
      for (let i = 0; i < numberOfPages; i++) {
        const res = await getAllERC20TransactionsFromToUser({
          User: this.user,
          PageNo: i,
          PageSize: 100,
        });
        csvDataPreprocessed = csvDataPreprocessed.concat(
          res.TransactionDetails
        );
        this.currentPercentageERC20Transactions = Math.round(
          (i / numberOfPages) * 100
        );
      }
      const csvData = csvDataPreprocessed
        .map((tx) => {
          return {
            Hash: tx.TransactionHash,
            Status: tx.Status == 1 ? "Confirmed" : "Failed",
            From: tx.From,
            To: tx.To,
            Contract: tx.Contract,
            Value: tx.Value,
          };
        })
        .map((tx) => [
          tx.Hash,
          tx.Status,
          tx.From,
          tx.To,
          tx.Contract,
          tx.Value,
        ]);

      const rows = [titles, ...csvData];
      const csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.user}_erc20_txns.csv`);
      document.body.appendChild(link);
      link.click();
      this.generatingERC20TransactionCSV = false;
      this.currentPercentageERC20Transactions = 0;
    },
    async exportCSVNfts() {
      const titles = ["Contract", "Name", "Symbol", "Token ID", "Token"];
      const requestPageSize =
        this.nfts.TotalCount >= 100000
          ? 10000
          : this.nfts.TotalCount >= 10000
          ? 1000
          : 10;
      const numberOfPages = Math.ceil(this.nfts.TotalCount / requestPageSize);
      if (numberOfPages > 3) {
        this.generatingNftsCsv = true;
      }
      let csvDataPreprocessed = [];
      for (let i = 0; i < numberOfPages; i++) {
        const res = await getAllNftsOfUser({
          User: this.user,
          PageNo: i,
          PageSize: requestPageSize,
        });
        csvDataPreprocessed = csvDataPreprocessed.concat(res.UserNFTs);
        this.currentPercentageNfts = Math.round(
          ((i + 1) / numberOfPages) * 100
        );
      }
      const csvData = csvDataPreprocessed
        .map((nft) => {
          return {
            Contract: nft.Contract,
            Name: nft && nft.ContractInfo ? nft.ContractInfo.Name : "",
            Symbol: nft && nft.ContractInfo ? nft.ContractInfo.Symbol : "",
            TokenId: nft.Token,
            Token: nft && nft.ContractInfo ? nft.Profile.image : "",
          };
        })
        .map((nft) => [
          nft.Contract,
          nft.Name,
          nft.Symbol,
          nft.TokenId,
          nft.Token,
        ]);

      const rows = [titles, ...csvData];
      const csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.user}_nfts.csv`);
      document.body.appendChild(link);
      link.click();
      this.generatingNftsCsv = false;
      this.currentPercentageNfts = 0;
    },
    async handleCurrentChange(newPage, newTab = null) {
      const newRouter = {
        query: {
          ...this.$route.query,
          page: newPage,
        },
        params: {
          ...this.$route.params,
        },
      };
      if (newTab !== null) {
        newRouter.query = {
          ...this.$route.query,
          tab: newTab,
          page: 1,
        };
      }
      await this.$router.replace(newRouter);
      this.currentPage = newPage;
      await this.getTxs();
      await this.getTxDetails();
      await this.getNftTransactions();
      await this.getERC20Transactions();
    },
    async handleCurrentChangeSecondary(newPage) {
      await this.$router.replace({
        query: {
          ...this.$route.query,
          pageSecondary: newPage,
        },
        params: {
          ...this.$route.params,
        },
      });
      this.currentPageSecondary = newPage;
      await this.getNfts();
    },
    copyAddress() {
      const clipboard = new Clipboard(".copy-success");
      clipboard.on("success", (e) => {
        e.clearSelection();
      });
      this.$message({ message: this.$t("message.copysuccess") });
    },
  },
  computed: {
    isSP() {
      return useMq().current === "xs";
    },
    getTitle() {
      if (this.isSP) {
        return `${this.user.substr(0, 6)}...${this.user.substr(-6)}`;
      }
      return this.user;
    },
    currentPage() {
      return Number(this.$route.query.page);
    },
    currentPageSecondary() {
      return Number(this.$route.query.pageSecondary);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/list.scss";
@media only screen and (max-width: $breakpoint-sm) {
  .mt20 {
    margin-top: 20px;
  }
}
.title-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
</style>
